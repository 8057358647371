import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { InactivityService } from '../services/inactivity.service';
import {
  isApiRequest,
  isLocalizationRequest,
  isLogOutRequest,
} from './interceptors.helper';

@Injectable({
  providedIn: 'root',
})
export class InactivityInterceptor implements HttpInterceptor {
  constructor(
    private readonly inactivityService: InactivityService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Records only API requests
    if (!isApiRequest(req) || isLocalizationRequest(req) || isLogOutRequest(req)) {
      return next.handle(req);
    }

    const isStillActive = this.inactivityService.tryRecordActivity();
    if (isStillActive) {
      return next.handle(req);
    }

    return throwError({ message: 'Session has expired.', url: req.url });
  }
}
