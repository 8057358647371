import { TranslateLoader } from '@ngx-translate/core';
import { Observable, from } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { getBaseUrl } from '@env/utils';

export function HttpTranslateLoaderFactory(client: HttpClient): TranslateLoader {
  return new HttpTranslateLoader(client);
}

export class HttpTranslateLoader implements TranslateLoader {
  cache: {[key: string]: any} = {};

  constructor(private readonly client: HttpClient) { }

  public getTranslation(lang: string): Observable<any> {
    if (this.cache[lang]) {
      return from(this.cache[lang]);
    }
    return this.client.get(`${getBaseUrl()}/api/translation/` + lang).pipe(
      tap((resp) => this.cache[lang] = resp),
    );
  }
}
