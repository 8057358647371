import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthorizeService } from './authorize.service';
import { Location } from '@angular/common';
import { JWTTokenService } from './jwt-token.service';

@Injectable({
  providedIn: 'root',
})
export class NavigateService {
  next?: string;
  currentUrl?: string;
  previousUrl?: string;

  constructor(
    private readonly router: Router,
    private readonly authorizeService: AuthorizeService,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.next = params.next;
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  navigateDashboard() {
    const path = this.authorizeService.getBasePath();
    return this.router.navigate(['/', ...path]);
  }

  navigateError(error: '404' | '401' | '403') {
    const path = this.authorizeService.getBasePath();
    const errorPath = ['error', error];
    if (path) {
      this.router.navigate(['/', ...path, ...errorPath]);
    } else {
      this.router.navigate(['/', ...errorPath]);
    }
  }

  hasHistory(): boolean {
    return window.history.length > 2;
  }

  goBack(fallbackRoute?: string) {
    if (this.previousUrl) {
      const url = this.previousUrl.split('?')[0];
      const queryParams = this.previousUrl.split('?')[1];
      const urlParams = new URLSearchParams(queryParams);
      this.router.navigate([url], {
        queryParams: Object.fromEntries(urlParams),
      });
    } else if (this.hasHistory()) {
      this.location.back();
    } else if (fallbackRoute) {
      this.router.navigate([fallbackRoute]);
    }
  }

  navigateBack(fallbackRoute?: string) {
    if (this.previousUrl) {
      this.router.navigate([this.previousUrl]);
    } else if (fallbackRoute) {
      this.router.navigate([fallbackRoute]);
    }
  }

  navigateBackWithLocation(fallbackRoute?: string) {
    if (this.hasHistory()) {
      this.location.back();
    } else if (fallbackRoute) {
      this.router.navigate([fallbackRoute]);
    }
  }

  goNext(fallbackRoute?: string) {
    if (this.next) {
      this.router.navigate([this.next]);
      return;
    }
    this.router.navigate([fallbackRoute]);
  }

  getNoCompanyUrl(next: string | null) {
    // if (this.jwtService.currentUser?.isFirstLogin) {
    //   return this.router.createUrlTree(['/', 'company', 'create-company'],
    //     { queryParams: { next } });
    // } else {
    return this.router.createUrlTree(['/', 'company', 'missing'], {
      queryParams: { next },
    });
    // }
  }
}
