import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-modal-header',
  templateUrl: './custom-modal-header.component.html',
  styleUrls: ['./custom-modal-header.component.scss'],
})
export class CustomModalHeaderComponent implements OnInit, OnDestroy {
  @Output()
  close = new EventEmitter();

  @Input()
  title: string = '';

  constructor() { }

  ngOnInit(): void {
    window.addEventListener('popstate', this.closeDialogOnBackButton.bind(this));
  }

  ngOnDestroy(): void {
    window.removeEventListener('popstate', this.closeDialogOnBackButton);
  }

  closeDialogOnBackButton(): void {
    this.onClose(null);
  }

  onClose($event: any) {
    this.close.emit($event);
  }
}
