import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatTimeDuration',
})
export class FormatTimeDurationPipe implements PipeTransform {
  transform(value?: number): string {
    if (value == undefined) {
      return '';
    }
    const hours = (Math.abs(value) / 60);
    const roundedHours = Math.floor(hours);
    const minutes = value - (roundedHours * 60);
    const roundedMinutes = Math.ceil(minutes);

    let result = `${roundedHours.toString().padStart(2, '0')}:${roundedMinutes.toString().padStart(2, '0')}`;

    if (value < 0) {
      result = `-${result}`;
    }
    return result;
  }
}
