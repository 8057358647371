import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'demo',
    loadChildren: () => import('./features/demo/demo.module').then((m) => m.DemoModule),
  },
  {
    path: '',
    loadChildren: () => import('@/layout/modules/anonymous-layout/anonymous-layout.module').then(
      (m) => m.AnonymousLayoutModule),
  },
  {
    path: '',
    loadChildren: () => import('@/layout/modules/authorized-layout/authorized-layout.module').then(
      (m) => m.AuthorizedLayoutModule),
  },
  {
    path: 'signing-completed',
    loadChildren: () => import('./features/e-sign/e-sign.module')
      .then((m) => m.ESignModule),
  },
  {
    path: 'palate',
    loadChildren: () => import('./features/palate/palate.module')
      .then((m) => m.PalateModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./features/errors/errors.module')
      .then((m) => m.ErrorsModule),
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    paramsInheritanceStrategy: 'always',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
