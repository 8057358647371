import { Injectable } from '@angular/core';
import { UiNotificationService } from '../../features/ui-notifications/ui-notification.service';
import { NavigateService } from './navigate.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(
    private readonly navigateService: NavigateService,
    private readonly notificationService: UiNotificationService,
  ) {
  }

  handleError(err: any, onNotFound?: () => void) {
    console.log(err);
    if (err.statusCode === 404) {
      if (onNotFound) {
        onNotFound();
      } else {
        this.handleNotFound();
      }
    } else {
      this.notificationService.error(err);
    }
  }

  handleNotFound() {
    this.navigateService.navigateError('404');
  }
}
