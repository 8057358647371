import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent {
  @Input()
  message: string;

  @Input()
  onSubmit?: () => void;

  @Input()
  onClose?: () => void;

  @ViewChild('footer')
  footer!: TemplateRef<any>;

  @Input()
  buttonText = 'OK';

  @Input()
  hasButton = true;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
  ) {
    this.config.showHeader = false;
    this.config.width = config.data.width || '270px';
    this.config.styleClass ??= 'tw-dialog';

    this.message = config.data.message;
    this.onSubmit = config.data.onSubmit;
    this.onClose = config.data.onClose;

    this.ref.onClose.subscribe((isSubmitted) => {
      if (!isSubmitted && this.onClose) {
        this.onClose();
      }
    });
  }

  submit() {
    if (this.onSubmit) {
      this.onSubmit();
    }
    this.ref.close(true);
  }
}
