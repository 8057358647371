import moment from 'moment';
import 'moment-precise-range-plugin';

export interface DatesDiff {
  years: number;
  months: number;
  days: number;
}

declare module 'moment' {
  // eslint-disable-next-line max-len,no-unused-vars
  function preciseDiff(start: string | Date | moment.Moment, end: string | Date | moment.Moment, convertToObject?: boolean): DatesDiff;

  interface Moment {
    localTimezone(): Moment;
  }
}

(moment as any).fn.localTimezone = function(): moment.Moment {
  return this.parseZone().local(true);
};
