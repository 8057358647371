import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import moment from 'moment';
import {
  CommunicationServiceClient,
  CommunicationServiceTokenResponse,
} from 'src/app/core/api-client';
import { unwrap } from 'src/app/core/extensions';
import {
  LocalStorageService,
} from 'src/app/core/services/local-storage.service';

export interface ChatTokenState {
  token?: string;
  expiresOn?: string;
  endpointUrl?: string;
  communicationServiceUserId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ChatTokenStore extends ComponentStore<ChatTokenState> {
  localStorageKeys = {
    token: 'azure_communication_service_token',
    expiresOn: 'azure_communication_service_expires_on',
    endpointUrl: 'azure_communication_service_endpoint_url',
    communicationServiceUserId: 'azure_communication_service_user_id',
  };

  constructor(
    private readonly communicationServiceClient: CommunicationServiceClient,
    private readonly localStorage: LocalStorageService,
  ) {
    super({
      token: undefined,
      expiresOn: undefined,
      endpointUrl: undefined,
    });
  }

  token$ = this.select((state) => state.token);

  init(forceRefresh = false) {
    if (!forceRefresh) {
      const token = this.localStorage.get(this.localStorageKeys.token);
      const expirationStr = this.localStorage.get(
        this.localStorageKeys.expiresOn);
      const endpointUrl = this.localStorage.get(
        this.localStorageKeys.endpointUrl);
      const communicationServiceUserId = this.localStorage.get(
        this.localStorageKeys.communicationServiceUserId);

      if (token && expirationStr && endpointUrl && communicationServiceUserId) {
        const expiration = moment(expirationStr);
        const now = moment();
        if (expiration.isAfter(now)) {
          this.setState({
            token: token,
            expiresOn: expirationStr,
            endpointUrl: endpointUrl,
            communicationServiceUserId: communicationServiceUserId,
          });
          return;
        }
      }
    }

    this.communicationServiceClient.getCommunicationServiceToken().pipe(
      unwrap<CommunicationServiceTokenResponse>(),
    ).subscribe((resp) => {
      const expiresOnStr = (resp.expiresOn as any);

      this.localStorage.set(this.localStorageKeys.token, resp.token);
      this.localStorage.set(this.localStorageKeys.expiresOn, expiresOnStr);
      this.localStorage.set(this.localStorageKeys.endpointUrl,
        resp.endpointUrl);
      this.localStorage.set(this.localStorageKeys.communicationServiceUserId,
        resp.communicationServiceUserId);

      this.setState({
        token: resp.token,
        expiresOn: expiresOnStr,
        endpointUrl: resp.endpointUrl,
        communicationServiceUserId: resp.communicationServiceUserId,
      });
    });
  }

  reset = this.updater(() => ({
    token: undefined,
    expiresOn: undefined,
    endpointUrl: undefined,
  }));
}
