export const environment = {
  production: true,
  baseUrl: null as string | null,
  demo: false,
  ga: '',
  plausible: null as string | null,
  titlePrefix: 'Altorney',
  homeUrl: '',
  features: {},
  flatFileEnv: 'us_env_g4DQRXCK',
  flatFileKey: 'pk_6e970dae18f345d0bf05c832c6ab6b01',
  sentryDsn: null as string | null,
  environmentName: 'default',
};
