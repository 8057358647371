export function buildQueryArguments(params: string[], value: any): any[] {
  const res = [];
  for (const param of params) {
    if (value[param] === null || value[param] === undefined) {
      res.push(null);
    } else {
      res.push(value[param]);
    }
  }
  return res;
}
