import { Injectable } from '@angular/core';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root',
})
export class DialogServicesRegistry {
  services: DialogService[] = [];

  constructor() {
  }

  register(service: DialogService) {
    this.services.push(service);
  }

  unregister(service: DialogService) {
    this.services = this.services.filter((s) => s !== service);
  }
}
