import {
  APP_INITIALIZER,
  LOCALE_ID,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalizationService } from './localization.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [],
  imports: [
    FormsModule,
    CommonModule,
    TranslateModule.forRoot(),
  ],
  exports: [
    TranslateModule,
  ],
})
export class InternationalizationModule {
  public static forRoot(
    config: any,
  ): ModuleWithProviders<InternationalizationModule> {
    return {
      ngModule: InternationalizationModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: initLocalizationService,
          deps: [LocalizationService],
          multi: true,
        },
        { provide: LOCALE_ID, useValue: config.locale_id }, // using the initial value
      ],
    };
  }
}

// eslint-disable-next-line valid-jsdoc
/**
 * Initialize the localization service.
 * @param {LocalizationService} service
 * @returns {() => Promise<void>}
 */
function initLocalizationService(service: LocalizationService) {
  return () => service.initService();
}
