import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DATE_FORMAT } from '../../../core/constants/altorney.constants';

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  constructor(
    private readonly datePipe: DatePipe,
  ) {
  }
  transform(value: Date | string | number | null | undefined): string {
    return this.datePipe.transform(value, DATE_FORMAT) ?? '';
  }
}
