import { HttpRequest } from '@angular/common/http';

export function isApiRequest(req: HttpRequest<any>) {
  return req.url.indexOf('/api/') > -1;
}

export function isLocalizationRequest(req: HttpRequest<any>) {
  return req.url.indexOf('/translation/') > -1;
}

export function isLogOutRequest(req: HttpRequest<any>) {
  return req.url.indexOf('/api/account/logout') > -1;
}
