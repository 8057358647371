<div class="tw-dialog-content">
  <app-custom-modal-header (close)="submit()"></app-custom-modal-header>
  <div class="tw-page tw-flex tw-flex-col tw-gap-4 tw-px-2 tw-pb-6">
    <div [innerHtml]="message">
    </div>
    <div class="tw-flex"
         *ngIf="hasButton">
      <button pButton pRipple
              class="tw-btn-primary"
              (click)="submit()">{{ buttonText }}</button>
    </div>
  </div>
</div>
