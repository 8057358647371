import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';
import { ToastComponent } from './toast/toast.component';


@NgModule({
  declarations: [
    ToastComponent,
  ],
  imports: [
    CommonModule,
    ToastModule,
    DialogModule,
    DynamicDialogModule,
  ],
  providers: [
    MessageService,
  ],
  exports: [
    ToastComponent,
  ],
})
export class UiNotificationsModule {
}
