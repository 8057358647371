<div class="tw-dialog-content">
  <app-custom-modal-header (close)="close()"></app-custom-modal-header>
  <div class="tw-page tw-flex tw-flex-col tw-gap-4 tw-px-2 tw-pb-6">
    <div>
      {{ message }}
    </div>
    <ul>
      <li *ngFor="let message of errors">
        {{ message }}
      </li>
    </ul>
    <div class="tw-flex">
      <button pButton pRipple
              class="tw-btn-primary"
              (click)="close()">{{ 'buttons.close' | translate }}
      </button>
    </div>
  </div>
</div>
