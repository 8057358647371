import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, timer } from 'rxjs';
import { debounce, filter } from 'rxjs/operators';
import {
  UiNotificationService,
} from '@/ui-notifications/ui-notification.service';
import { JWTTokenService } from './jwt-token.service';
import { LocalStorageService } from './local-storage.service';
import { LogOutService } from './log-out.service';
import {
  TimeClockService,
} from '@/reviewer/modules/reviewer-clock/time-clock.service';
import { UserSettingsStore } from '@/account/stores/user-settings.store';
import { TrackingService } from '@core/services/tracking.service';
import { DialogServicesRegistry } from '@core/services/dialogs';
import { ConfirmationService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class InactivityService implements OnDestroy {
  private readonly lastActivityDateKey: string = 'last_activity_date';
  private minutesBeforeTimeOut: number = 15;
  private readonly dialogDebounceMs = 200;

  private _inactiveSubject = new Subject<string | undefined>();

  private timeoutModalShown = false;

  interval: NodeJS.Timeout;

  forcedActivityPeriodInterval: NodeJS.Timeout | undefined;

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly notificationService: UiNotificationService,
    private readonly logOutService: LogOutService,
    private readonly jwtService: JWTTokenService,
    private readonly router: Router,
    private readonly timeClockService: TimeClockService,
    private readonly settingsStore: UserSettingsStore,
    private readonly tracking: TrackingService,
    private readonly dialogsRegistry: DialogServicesRegistry,
    private readonly confirmationService: ConfirmationService,
  ) {
    this.interval = setInterval(() => {
      if (this.shouldTimeOutUser()) {
        this.recordInactive();
      }
    }, 1000);

    this._inactiveSubject.asObservable().pipe(
      untilDestroyed(this),
      debounce(() => timer(this.dialogDebounceMs)),
      filter(() => !this.timeoutModalShown),
    ).subscribe(async (next) => {
      this.tracking.captureMessage('user_timeout', {
        extra: {
          minutesBeforeTimeOut: this.minutesBeforeTimeOut,
          lastActivityDate: this.localStorageService.get(
            this.lastActivityDateKey),
        },
      });
      this.timeoutModalShown = true;
      // Hide all dialogs before logging out
      for (const dialog of this.dialogsRegistry.services.values()) {
        dialog.closeAll();
      }
      this.confirmationService.close();
      await this.logOutService.logout(next ?? this.router.url);
      await this.showTimeOutModal();
      this.timeoutModalShown = false;
    });

    this.settingsStore.minutesBeforeTimeOut$.pipe(
      untilDestroyed(this),
    ).subscribe((minutes) => {
      this.minutesBeforeTimeOut = minutes;
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  recordInactive(next?: string) {
    this._inactiveSubject.next(next);
  }

  recordActivity(): void {
    this.localStorageService.set(this.lastActivityDateKey,
      new Date().toUTCString());
  }

  shouldTimeOutUser(): boolean {
    const lastActivityDateString = this.localStorageService.get(
      this.lastActivityDateKey);
    const lastActivityDate = Date.parse(lastActivityDateString!);

    const millisecondsInSecond = 1000;
    const secondsInMinute = 60;
    const diffInMinutes = (Date.now() - lastActivityDate) /
      (millisecondsInSecond * secondsInMinute);

    const shouldTimeOut = !!lastActivityDateString && diffInMinutes >
      this.minutesBeforeTimeOut;
    return shouldTimeOut && this.timeClockService.canTimeOut();
  }

  showTimeOutModal(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.notificationService.error(
        'Your session has expired. Please sign in again.', {
          onSubmit: () => resolve(),
          onClose: () => resolve(),
          size: 'tw-dialog-sm !tw-w-[235px]',
        });
    });
  }

  isActive(): boolean {
    return !this.jwtService.jwtToken || !this.shouldTimeOutUser();
  }

  tryRecordActivity(next?: string): boolean {
    if (!this.jwtService.jwtToken) {
      return true;
    }

    const isActive = !this.shouldTimeOutUser();
    if (isActive) {
      this.recordActivity();
    } else {
      this.recordInactive(next);
    }
    return isActive;
  }

  beginForcedActivityPeriod(): void {
    this.endForcedActivityPeriod();
    this.forcedActivityPeriodInterval = setInterval(() => {
      this.recordActivity();
    }, 5000);
  }

  endForcedActivityPeriod(): void {
    if (this.forcedActivityPeriodInterval) {
      clearInterval(this.forcedActivityPeriodInterval);
    }
  }
}
