import { Injectable, OnDestroy, Type } from '@angular/core';
import { DialogService as PrimeDialogService } from 'primeng/dynamicdialog';
import {
  DynamicDialogConfig,
} from 'primeng/dynamicdialog/dynamicdialog-config';
import {
  DialogServicesRegistry,
} from '@core/services/dialogs/dialog-services.registry';

@Injectable()
export class DialogService implements OnDestroy {
  constructor(
    private readonly service: PrimeDialogService,
    private readonly dialogServicesRegistry: DialogServicesRegistry) {
    this.dialogServicesRegistry.register(this);
  }

  ngOnDestroy(): void {
    this.dialogServicesRegistry.unregister(this);
  }

  open(componentType: Type<any>, config: DynamicDialogConfig) {
    return this.service.open(componentType, config);
  }

  closeAll() {
    this.service.dialogComponentRefMap.forEach((componentRef) => {
      componentRef.instance.close();
    });
  }
}
