import { Component, Input } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-errors-dialog',
  templateUrl: './errors-dialog.component.html',
  styleUrls: ['./errors-dialog.component.scss'],
})
export class ErrorsDialogComponent {
  @Input()
  message: string;

  @Input()
  errors: string[] = [];

  @Input()
  onClose?: () => void;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
  ) {
    this.config.showHeader = false;
    this.config.width = config.data.width || '270px';
    this.config.styleClass = 'tw-dialog';

    this.message = config.data.message;
    this.errors = config.data.errors;
    this.onClose = config.data.onClose;

    this.ref.onClose.subscribe((isSubmitted) => {
      if (!isSubmitted && this.onClose) {
        this.onClose();
      }
    });
  }

  close() {
    this.ref.close(true);
  }

}
