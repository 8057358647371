import { Injectable } from '@angular/core';
import { JWTTokenService } from './jwt-token.service';
import { Roles } from '../constants/roles';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeService {
  constructor(
    private readonly jwtService: JWTTokenService,
    private readonly localStorage: LocalStorageService,
  ) {
  }

  isAuthenticated(): boolean {
    return this.jwtService.getToken() != null;
  }

  isCompanyUser(): boolean {
    return this.jwtService.getRoles()?.includes(Roles.CompanyUser) ?? false;
  }

  isFirstLogIn(): boolean {
    return this.jwtService.getIsFirstLogIn();
  }

  isReviewer(): boolean {
    return this.jwtService.getRoles()?.includes(Roles.Reviewer) ?? false;
  }

  isAdmin(): boolean {
    return this.jwtService.getRoles()?.includes(Roles.Admin) ?? false;
  }

  getBasePath(): string[] {
    if (this.isReviewer()) {
      return ['reviewer'];
    }

    if (this.isCompanyUser()) {
      // const companyId = this.jwtService.getCompanyId();
      // if (companyId) {
      //   return ['company', ...companyId];
      // }
      return ['company'];
    }

    if (this.isAdmin()) {
      return ['admin'];
    }

    return [''];
  }

  logout() {
    this.localStorage.clear();
    sessionStorage.clear();
  }
}
