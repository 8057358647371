/* eslint-disable valid-jsdoc */
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WindowService } from '@core/services/window.service';

/**
 * Class representing the translation service.
 */
@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  readonly lang = [
    'en-US',
  ];
  readonly defaultLang = 'en-US';
  private _localeId: string = 'en-US'; // default
  private windowRef: any;

  constructor(
    @Inject(LOCALE_ID) private localeId: string,
    private translateService: TranslateService,
    private windowService: WindowService,
  ) {
    this._localeId = localeId;
    this.windowRef = this.windowService.windowRef;
  }

  /**
   * Initialize the service.
   * @return {Promise<void>}
   */
  public initService(): Promise<void> {
    this._localeId = this.getCurrentLanguage();
    return this.useLanguage(this._localeId);
  }

  // eslint-disable-next-line valid-jsdoc
  /**
   * change the selected language
   * @return {Promise<void>}
   */
  public useLanguage(lang: string): Promise<void> {
    const isSupported = this.lang.find(
      (supportedLanguage) => supportedLanguage === lang);

    if (isSupported) {
      return this.translateService.use(lang).toPromise();
    } else {
      return this.translateService.use('en-US').toPromise();
    }
  }

  public getCurrentLanguage(): string {
    // get Language from Storage.
    // if no language is set get it from the browser.
    // else return the default language.
    return localStorage.getItem('language') ||
      this.windowRef.navigator.language || 'en-US';
  }
}
