import { Injectable } from '@angular/core';

import * as Sentry from '@sentry/angular-ivy';
import {
  ExclusiveEventHintOrCaptureContext,
} from '@sentry/core/types-ts3.8/utils/prepareEvent';
import { CaptureContext } from '@sentry/types';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  constructor() {
  }

  setUser(user: Sentry.User | null) {
    Sentry.setUser(user);
  }

  captureMessage(event: string, context?: CaptureContext) {
    Sentry.captureMessage(event, context);
  }

  captureException(exception: any, hint?: ExclusiveEventHintOrCaptureContext) {
    Sentry.captureException(exception);
  }
}
