export enum Roles {
  Admin = 'Admin',
  Reviewer = 'Reviewer',
  CompanyUser = 'CompanyUser',
}

export enum RoleLabels {
  Admin = 'Admin',
  CompanyUser = 'Company',
  ReviewerStandard = 'Reviewer',
  ReviewerInternal = 'Internal',
}

export enum CompanyRoles {
  CompanyOwner = 'CompanyOwner',
  CompanyStandard = 'CompanyStandard',
}

export enum ReviewerRoles {
  ReviewerStandard = 'ReviewerStandard',
  ReviewerInternal = 'ReviewerInternal',
}
