import { InjectionToken } from '@angular/core';
import { DialogService as PrimeDialogService } from 'primeng/dynamicdialog';
import { DialogService } from './dialog.service';

export const DIALOG_SERVICES = new InjectionToken<DialogService[]>(
  'DIALOG_SERVICES');

export function provideDialog() {
  return [
    {
      provide: PrimeDialogService,
    },
    {
      provide: DialogService,
    },
  ];
}
